import { batchRecordStatus } from "@/model/record/recordModel";
import { eventNames } from "@/model/common/events/eventConst";
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";

export const batchDialogMixin = {
  mixins: [slottableMixin],

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    /**
     * Set Operation Status per each Record
     * @param recordId
     * @param status
     * @param message
     */
    setStatus(recordId, status, message) {
      const targetRecord = this.batchOperationStatus.find(
        item => item.recordId === recordId
      );
      if (targetRecord) {
        targetRecord.status = status;
        targetRecord.message = message;
      }
    },

    /**
     *  close Dialog
     */
    closeAction() {
      this.isCanceled = true;
      this.$emit(eventNames.close);
    },

    /**
     *  cancel Operation
     */
    cancelAction() {
      this.isCanceled = true;
      this.batchOperationStatus.forEach(record => {
        if (record.status === batchRecordStatus.Ready) {
          record.status = batchRecordStatus.Canceled;
          record.message = "Aborted";
        }
      });
    },

    /**
     * Set Record Operation Status
     */
    setRecordOperationStatus() {
      this.batchOperationStatus = [];
      this.recordList?.forEach(record => {
        // Create an object with recordId and status "Ready" and push it to batchOperationStatus
        this.batchOperationStatus.push({
          recordId: record?.id,
          status: batchRecordStatus.Ready,
          message: "Ready"
        });
      });
    },

    /**
     * Event On Batch Dialog Opened
     */
    onDialogOpened() {
      this.setRecordOperationStatus();
      this.isCanceled = false;
    }
  }
};
